import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { NgxScrollTopModule } from "ngx-scrolltop";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { NgxPaginationModule } from "ngx-pagination";
import { SearchFilterPipe } from "./search-filter.pipe";
import { CommonModule } from "@angular/common";

@NgModule({
  declarations: [SearchFilterPipe],
  imports: [
    CommonModule,
    // AppRoutingModule,
    // BrowserModule,
    // BrowserAnimationsModule,
    NgxScrollTopModule,
    NgxPaginationModule,
  ],
  exports: [SearchFilterPipe],
  providers: [SearchFilterPipe],
  bootstrap: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
