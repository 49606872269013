import { Component, OnInit } from "@angular/core";
import { ThemeCustomizerService } from "../../common/theme-customizer/theme-customizer.service";
import { HeaderStyleOneComponent } from "../../common/header-style-one/header-style-one.component";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { NgxPaginationModule } from "ngx-pagination";
import { CommonModule, PlatformLocation } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { AppModule } from "src/app/app.module";
import { SearchFilterPipe } from "src/app/search-filter.pipe";
import { DataService } from "src/app/core/services/data.service";

@Component({
  selector: "app-blog-full-width-page",
  standalone: true,
  imports: [
    HeaderStyleOneComponent,
    RouterLink,
    NgxPaginationModule,
    CommonModule,
    FormsModule,
    AppModule,
  ],
  templateUrl: "./blog-full-width-page.component.html",
  styleUrls: ["./blog-full-width-page.component.scss"],
  providers: [SearchFilterPipe]
})
export class BlogFullWidthPageComponent implements OnInit {
  isToggled = false;
  q: any;
  dataList = [
    {
      imgurl: "assets/img/blog/blog1.jpg",
      blogType: "Learning",
      author: "Steven Smith",
      date: "August 30, 2024",
      describtion: "World largest elephant toothpaste experiment in 2024",
      userProfileurl: "assets/img/user3.jpg"
    },
    {
      imgurl: "assets/img/blog/blog2.jpg",
      blogType: "Education",
      author: "Lina DSouja",
      date: "August 29, 2024",
      describtion: "Most Popular Education Posts Of The Week 20-26 Aug",
      userProfileurl: "assets/img/user2.jpg"
    },
    {
      imgurl: "assets/img/blog/blog3.jpg",
      blogType: "Management",
      author: "David Malan",
      date: "August 28, 2024",
      describtion: "How to enhance education quality management system",
      userProfileurl: "assets/img/user1.jpg"
    },
    {
      imgurl: "assets/img/blog/blog4.jpg",
      blogType: "Ideas",
      author: "David Warner",
      date: "August 27, 2024",
      describtion: "Global education: Ideas for the way move forward",
      userProfileurl: "assets/img/user5.jpg"
    },
    {
      imgurl: "assets/img/blog/blog5.jpg",
      blogType: "Workforce",
      author: "Olivar Waise",
      date: "August 26, 2024",
      describtion: "New report reimagines the broader education workforce",
      userProfileurl: "assets/img/user6.jpg"
    },
    {
      imgurl: "assets/img/blog/blog6.jpg",
      blogType: "Education",
      author: "Lina DSouja",
      date: "August 29, 2024",
      describtion: "What’s Going On in This Picture? | Jan. 13, 2024",
      userProfileurl: "assets/img/user2.jpg"
    },
    {
      imgurl: "assets/img/blog/blog7.jpg",
      blogType: "Learning",
      author: "Steven Smith",
      date: "August 26, 2024",
      describtion: "Connecting Math and Science to Reading and Writing",
      userProfileurl: "assets/img/user6.jpg"
    },
    {
      imgurl: "assets/img/blog/blog8.jpg",
      blogType: "Education",
      author: "Lina DSouja",
      date: "August 29, 2024",
      describtion: "How to Introduce Meditation to the High School",
      userProfileurl: "assets/img/user2.jpg"
    },
    {
      imgurl: "assets/img/blog/blog1.jpg",
      blogType: "Learning",
      author: "Steven Smith",
      date: "August 30, 2024",
      describtion: "World largest elephant toothpaste experiment in 2024",
      userProfileurl: "assets/img/user3.jpg"
    },
    {
      imgurl: "assets/img/blog/blog2.jpg",
      blogType: "Education",
      author: "Lina DSouja",
      date: "August 29, 2024",
      describtion: "Most Popular Education Posts Of The Week 20-26 Aug",
      userProfileurl: "assets/img/user2.jpg"
    },
    {
      imgurl: "assets/img/blog/blog3.jpg",
      blogType: "Management",
      author: "David Malan",
      date: "August 28, 2024",
      describtion: "How to enhance education quality management system",
      userProfileurl: "assets/img/user1.jpg"
    },
    {
      imgurl: "assets/img/blog/blog4.jpg",
      blogType: "Ideas",
      author: "David Warner",
      date: "August 27, 2024",
      describtion: "Global education: Ideas for the way move forward",
      userProfileurl: "assets/img/user5.jpg"
    },
    {
      imgurl: "assets/img/blog/blog5.jpg",
      blogType: "Workforce",
      author: "Olivar Waise",
      date: "August 26, 2024",
      describtion: "New report reimagines the broader education workforce",
      userProfileurl: "assets/img/user6.jpg"
    }
  ];

  searchText: string;
  category : string;
 subCategory : string;

  blogPosts : any[] = [];
  currentPage = 1;
  pagination = {
    hasNextPage: false,
    hasPrevPage: false,
    totalPages:1
  }
  isLoading = false;
  isError = false;
  constructor(
    public themeService: ThemeCustomizerService,
    private pLocation: PlatformLocation,
    private data:DataService,
    private router:Router,
    private activeRoute:ActivatedRoute
  ) {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
    activeRoute.queryParams.subscribe(params=>{

        this.category = null;
        this.subCategory = null;
        if(params["searchText"]){
            this.searchText = params["searchText"]
        }
        if(params["c"]){
            this.category = params["c"]
        }
        if(params["sc"]){
            this.subCategory = params["sc"]
        }
        this.getBlogPosts()
    })
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  ngOnInit(): void {
    this.getBlogPosts()

  }


  getBlogPosts(){
    this.isLoading = true;
    window.scrollTo(0,0);
    this.data.getPosts(this.currentPage, this.category,this.subCategory,this.searchText).subscribe((res:any)=>{
        this.isLoading = false;
        this.blogPosts = res.docs;
        this.pagination = {...res};
        this.currentPage = res?.page;
    },err=>{
        setTimeout(() => {
            this.isLoading = false;
        }, 500);
        this.isError = true;
    })
  }
  searchBlogPosts(){
    this.router.navigate([],{queryParams:{searchText:this.searchText}})
    this.getBlogPosts()
  }

  getPagesArray(){
    return Array(this.pagination?.totalPages || 1).fill(0).map((x, i) => i + 1);
  }

  nextPage(){
    if(!this.pagination.hasNextPage)return;
    this.currentPage += 1;
    this.getBlogPosts();
  }

  previousPage(){
    if(!this.pagination.hasPrevPage)return;
    this.currentPage -= 1;
    this.getBlogPosts();
  }

  moveToPage(page:number){
    this.currentPage = page;
    this.getBlogPosts();
  }

  openPostDetails(postUrl:any){
    this.router.navigate([`/single-blog/${postUrl}`])
  }

  searchNow($event:any){
    if($event.key == 'Enter'){
        this.searchBlogPosts()
    }
  }

  resetSearch(){
    this.searchText = '';
    this.router.navigate([],{queryParams:{searchText:null}})
    this.getBlogPosts()
  }
}
