<app-header-style-one></app-header-style-one>

<div class="page-title-area page-title-style-three item-bg4">
    <div class="container">
        <div class="page-title-content">
            <h2>{{pageData?.title}}</h2>
            <p style="color: white ;">{{pageData?.description}}</p>
        </div>
    </div>
</div>

<div class="blog-area ptb-100" [class.dark-blog-area]="themeService.isDark()">
    <div class="container-fluid">
        <!-- <div class="row">
            <div class="col-lg-3">
            </div>
            <div class="col-lg-6">
                <div class="pagine">
                    <div class="search-overlay-form">
                        <form>
                            <input type="text"
                            class="input-search"
                             placeholder="Search here..."
                             [(ngModel)]="searchText"
                            (keydown)="searchNow($event)"
                            [ngModelOptions]="{standalone: true}"
                            id="searchInput"
                            >

                            <button type="submit"
                            [disabled]="isLoading"
                            (click)="searchBlogPosts()">
                            <i class='bx bx-search-alt'></i>
                        </button>

                        </form>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">

            </div>
        </div> -->
        <div class="row">
            <div class="col-xxl-3 col-lg-4 col-md-6"
            *ngFor="let post of pageData?.posts" (click)="openPostDetails(post?.url)">
            <div class="single-blog-post mb-30">
                <div class="post-image">
                    <a

                    class="d-block">
                        <img  [src]="post.imageUrl" [alt]="post.imageAlt"  alt="image">
                    </a>
                    <div class="tag">
                        <a >{{ post?.category?.name }}</a>
                    </div>
                </div>
                <div class="post-content">
                    <ul class="post-meta">
                        <li class="post-author">
                            <img [src]="post?.publishedBy?.image" class="d-inline-block rounded-circle mr-2" alt="image">
                            <a  class="d-inline-block">Continue Reading</a>
                        </li>
                        <li><a>&nbsp;&nbsp;-&nbsp;{{post?.createdAt | date : "mediumDate"}}</a></li>
                    </ul>
                    <h3 style="font-weight: 400 !important;"><a role="button" class="d-inline-block">{{post?.title.slice(0, 50) + "..."}}</a></h3>
                    <!-- <a role="button" class="read-more-btn">Read More <i
                            class='bx bx-right-arrow-alt'></i></a> -->
                </div>
            </div>
        </div>
            <div class="col-lg-12 col-md-12 my-5" *ngIf="isLoading">
                <div
                  class="text-center d-flex justify-content-center align-items-center flex-column gap-3"
                  style="font-size: 20px"
                >
                  <span
                    class="spinner-border spinner-border-lg spinner-color"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading Blog Posts...
                </div>
              </div>
              <div
                class="col-lg-12 col-md-12 my-5"
                *ngIf="pageData?.posts?.length == 0 && !isLoading"
              >
                <div class="text-center">
                  <h3>No Blog Posts Found</h3>
                </div>
              </div>
        </div>
    </div>
</div>
