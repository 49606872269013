<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Contact Us</h3>
                    <ul class="contact-us-link">
                        <!-- <li>
                            <i class='bx bx-map'></i>
                            <a href="#" target="_blank">2750 Quadra Street Victoria Road, New York, United States</a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:+11234567890">+1 (123) 456 7890</a>
                        </li> -->
                        <li>
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:contact@rvtechbytes.com">contact&#64;rvtechbytes.com</a>
                        </li>
                    </ul>
                    <ul class="social-link">
                        <!-- <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li> -->
                        <li><a href="https://www.linkedin.com/in/radhika-vemura-a051339/" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <!-- <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li> -->
                        <li><a href="mailto:contact@rvtechbytes.com" class="d-block" target="_blank"><i class='fa fa-envelope'></i></a></li>
                        <!-- <li><a href="#" class="d-block" target="_blank"><i class='fa fa-globe'></i></a></li> -->
                        <!-- <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> -->
                    </ul>
                </div>
            </div>
            <!-- <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Support</h3>
                    <ul class="support-link">
                        <li><a routerLink="/">Privacy</a></li>
                        <li><a routerLink="/">FAQ's</a></li>
                        <li><a routerLink="/">Support</a></li>
                        <li><a routerLink="/">Terms</a></li>
                        <li><a routerLink="/">Condition</a></li>
                        <li><a routerLink="/">Policy</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Useful Link</h3>
                    <ul class="useful-link">
                        <li><a routerLink="/">Web Design</a></li>
                        <li><a routerLink="/">UI/UX Design</a></li>
                        <li><a routerLink="/">WP Development</a></li>
                        <li><a routerLink="/">App</a></li>
                        <li><a routerLink="/">Whitepaper</a></li>
                        <li><a routerLink="/">Web Development</a></li>
                    </ul>
                </div>
            </div> -->
            <div class="col">
                <div class="single-footer-widget mb-30">
                    <h3>Newsletter</h3>
                    <div class="newsletter-box">
                        <p>subscribing to our newsletter, you agree to get our the latest information via email from us.</p>
                        <form *ngIf="!isSubscribed" class="newsletter-form" (ngSubmit)="onSubmit()" [formGroup]="subscribeForm">

                            <label>Your e-mail address:</label>
                            <div class="d-flex w-100 align-items-center">
                            <input type="email" class="input-newsletter w-75 me-1" placeholder="Enter your email" formControlName="email">
                            <button type="submit" class="mt-0 w-auto">Subscribe</button>
                            </div>
                            <div class="invalid-feedback d-block ps-1" *ngIf="submitted">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                                <div *ngIf="f.email.errors.email">
                                    Please enter a valid email address
                                </div>
                            </div>
                        </form>
                        <div *ngIf="isSubscribed">
                            <p class="text-success">Thank you for subscribing to our newsletter!</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom-area">
        <div class="container">
            <div class="logo">
                <a routerLink="/" class="d-inline-block"><img src="assets/img/black-logo.png" alt="image" width="250"></a>
            </div>
            <p>Copyright © 2024 <a href="https://RVTechBytes.com/" target="_blank"> RVTechBytes.com</a></p>
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>
