<app-header-style-one></app-header-style-one>
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul >
                <li><a routerLink="/">Home</a></li>
                <li><a >View Details</a></li>
            </ul>
            <h2 style="font-size: 28px;" >{{
                BlogPostData?.title
            }}</h2>
             <!-- <div class="action-div">
                <div class="content-div mt-2">
                    <button data-toggle="tooltip" data-placement="top" title="Listen This Post" *ngIf="!generatedAudio" class="btn btn-dark me-2" (click)="generateAudio()" type="button">
                        <svg *ngIf="!isLoadingAudio" xmlns="http://www.w3.org/2000/svg" style="color: white; height: 40px;" viewBox="0 0 32 32"><path fill="none" d="M11 23a1 1 0 0 1-1-1V10a1 1 0 0 1 1.447-.894l12 6a1 1 0 0 1 0 1.788l-12 6A1.001 1.001 0 0 1 11 23"/><path fill="currentColor" d="M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2m7.447 14.895l-12 6A1 1 0 0 1 10 22V10a1 1 0 0 1 1.447-.894l12 6a1 1 0 0 1 0 1.788"/></svg>

                        <span
                        *ngIf="isLoadingAudio"
                        class="spinner-border spinner-border-md"
                        role="status"
                        aria-hidden="true"
                        ></span>
                    </button>
                    <audio *ngIf="generatedAudio" class="me-2" [src]="generatedAudio" controls></audio>
                    <button data-toggle="tooltip" data-placement="top" title="Share This Post" class="btn btn-dark" type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" style="color: white; height: 40px;" viewBox="0 0 24 24"><path fill="currentColor" d="M18 22q-1.25 0-2.125-.875T15 19q0-.175.025-.363t.075-.337l-7.05-4.1q-.425.375-.95.588T6 15q-1.25 0-2.125-.875T3 12t.875-2.125T6 9q.575 0 1.1.213t.95.587l7.05-4.1q-.05-.15-.075-.337T15 5q0-1.25.875-2.125T18 2t2.125.875T21 5t-.875 2.125T18 8q-.575 0-1.1-.212t-.95-.588L8.9 11.3q.05.15.075.338T9 12t-.025.363t-.075.337l7.05 4.1q.425-.375.95-.587T18 16q1.25 0 2.125.875T21 19t-.875 2.125T18 22"/></svg>
                    </button>
                </div>
             </div> -->
        </div>
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row" [style.display]="isLoading || responseError ? 'none' : ''">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">

                    <div class="article-content mt-0">
                         <div id="blogDescriptionDiv"></div>
                         <div class="entry-meta mt-5">
                            <ul>
                                <li>
                                    <i class='bx bx-folder-open'></i>
                                    <span>Category</span>
                                    <a >{{BlogPostData?.category?.name}}</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>Posted Date</span>
                                    <a >{{BlogPostData?.createdAt | date:'mediumDate'}}</a>
                                </li>
                                <li>
                                    <i class='bx bx-calendar'></i>
                                    <span>Last Updated</span>
                                    <a >{{BlogPostData?.updatedAt | date:'mediumDate'}}</a>
                                </li>
                            </ul>
                        </div>
                        </div>
                    <div class="article-footer mb-5">
                        <div class="article-tags">
                            <span><i class='bx bx-purchase-tag'></i></span>
                            <a class="main-color" role="button" *ngFor="let tag of BlogPostData?.tags?.slice(0,3); let last = last">
                                {{tag?.name?.charAt(0)?.toUpperCase() + tag?.name?.slice(1)}}<ng-container *ngIf="!last">,</ng-container>
                              </a>

                        </div>
                        <div class="article-share">
                            <ul class="social">
                                <li><span>Share:</span></li>
                                <li><a href="https://www.facebook.com/sharer/sharer.php?u={{getPostShareUrl()}}" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="https://twitter.com/intent/tweet?text={{BlogPostData?.title}}&url={{getPostShareUrl()}}" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="https://www.linkedin.com/shareArticle?mini=true&url={{getPostShareUrl()}}" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <!-- <div class="article-author">
                        <div class="author-profile-header"></div>
                        <div class="author-profile pb-0">
                            <div class="author-profile-title ">
                                <img [src]="BlogPostData?.publishedBy?.image" style="object-fit: cover;" class="shadow-sm" alt="image">
                                <div class="author-profile-title-details">
                                    <div class="author-profile-details">
                                        <h4>{{BlogPostData?.publishedBy?.name}}</h4>
                                        <span class="d-block">{{BlogPostData?.publishedBy?.role}}</span>
                                    </div>
                                </div>
                            </div>
                            <p class="pb-3">{{BlogPostData?.publishedBy?.description}}</p>
                        </div>
                    </div> -->
                    <div class="odemy-post-navigation  d-flex flex-wrap mt-lg-2 mt-5" *ngIf="BlogPostData?.prevPost?.url && BlogPostData?.nextPost?.url">
                        <div class="prev-link-wrapper" >
                            <div class="info-prev-link-wrapper ">
                                <a role="button" (click)="openPostDetails(BlogPostData?.prevPost?.url)">
                                    <span class="image-prev">
                                        <img style="width: 125px;height: 60px;" [src]="BlogPostData?.prevPost?.imageUrl" [alt]="BlogPostData?.prevPost?.imageAlt">
                                        <span class="post-nav-title">Prev</span>
                                    </span>
                                    <span class="prev-link-info-wrapper">
                                        <span class="prev-title">{{BlogPostData?.prevPost?.title?.slice(0,45) + '...'}}</span>
                                        <span class="meta-wrapper">
                                            <span class="date-post">{{BlogPostData?.prevPost?.createAt  | date:'mediumDate'}}</span>
                                        </span>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div class="next-link-wrapper" >
                            <div class="info-next-link-wrapper">
                                <a  role="button" (click)="openPostDetails(BlogPostData?.nextPost?.url)">
                                    <span class="next-link-info-wrapper">
                                        <span class="prev-title">{{BlogPostData?.nextPost?.title?.slice(0,45) + '...'}}</span>

                                        <span class="meta-wrapper">
                                            <span class="date-post">{{BlogPostData?.nextPost?.createAt  | date:'mediumDate'}}</span>
                                        </span>
                                    </span>
                                    <span class="image-next">
                                        <img style="width: 125px;height: 60px;"  [src]="BlogPostData?.nextPost?.imageUrl" [alt]="BlogPostData?.nextPost?.imageAlt">
                                        <span class="post-nav-title">Next</span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="single-footer-widget mb-5 mt-5">
                            <h3>Newsletter</h3>
                            <div class="newsletter-box">
                                <p>subscribing to our newsletter, you agree to get our the latest information via email from us.</p>
                                <form *ngIf="!isSubscribed" class="newsletter-form" (ngSubmit)="onSubmit()" [formGroup]="subscribeForm">

                                    <label>Your e-mail address:</label>

                                    <input type="email" class="input-newsletter" placeholder="Enter your email" formControlName="email">
                                    <div class="invalid-feedback d-block ps-1" *ngIf="submitted">
                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                        <div *ngIf="f.email.errors.email">
                                            Please enter a valid email address
                                        </div>
                                    </div>
                                    <button type="submit" class="w-auto m-0 ms-auto me-auto mt-2">Subscribe</button>


                                </form>
                                <div *ngIf="isSubscribed">
                                    <p class="text-success">Thank you for subscribing to our newsletter!</p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="comments-area">
                        <h3 class="comments-title">2 Comments:</h3>
                        <ol class="comment-list">
                            <li class="comment">
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="assets/img/user1.jpg" class="avatar" alt="image">
                                            <b class="fn">John Jones</b>
                                            <span class="says">says:</span>
                                        </div>
                                        <div class="comment-metadata">
                                            <a routerLink="/blog-1"><span>April 24, 2019 at 10:59 am</span></a>
                                        </div>
                                    </footer>
                                    <div class="comment-content">
                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                    </div>
                                    <div class="reply">
                                        <a  class="comment-reply-link">Reply</a>
                                    </div>
                                </article>
                                <ol class="children">
                                    <li class="comment">
                                        <article class="comment-body">
                                            <footer class="comment-meta">
                                                <div class="comment-author vcard">
                                                    <img src="assets/img/user2.jpg" class="avatar" alt="image">
                                                    <b class="fn">Steven Smith</b>
                                                    <span class="says">says:</span>
                                                </div>
                                                <div class="comment-metadata">
                                                    <a routerLink="/blog-1"><span>April 24, 2019 at 10:59 am</span></a>
                                                </div>
                                            </footer>
                                            <div class="comment-content">
                                                <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                            </div>
                                            <div class="reply">
                                                <a  class="comment-reply-link">Reply</a>
                                            </div>
                                        </article>
                                        <ol class="children">
                                            <li class="comment">
                                                <article class="comment-body">
                                                    <footer class="comment-meta">
                                                        <div class="comment-author vcard">
                                                            <img src="assets/img/user3.jpg" class="avatar" alt="image">
                                                            <b class="fn">Sarah Taylor</b>
                                                            <span class="says">says:</span>
                                                        </div>
                                                        <div class="comment-metadata">
                                                            <a routerLink="/blog-1"><span>April 24, 2019 at 10:59 am</span></a>
                                                        </div>
                                                    </footer>
                                                    <div class="comment-content">
                                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                                    </div>
                                                    <div class="reply">
                                                        <a  class="comment-reply-link">Reply</a>
                                                    </div>
                                                </article>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                            <li class="comment">
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="assets/img/user4.jpg" class="avatar" alt="image">
                                            <b class="fn">John Doe</b>
                                            <span class="says">says:</span>
                                        </div>
                                        <div class="comment-metadata">
                                            <a routerLink="/blog-1"><span>April 24, 2019 at 10:59 am</span></a>
                                        </div>
                                    </footer>
                                    <div class="comment-content">
                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                    </div>
                                    <div class="reply">
                                        <a  class="comment-reply-link">Reply</a>
                                    </div>
                                </article>
                                <ol class="children">
                                    <li class="comment">
                                        <article class="comment-body">
                                            <footer class="comment-meta">
                                                <div class="comment-author vcard">
                                                    <img src="assets/img/user1.jpg" class="avatar" alt="image">
                                                    <b class="fn">James Anderson</b>
                                                    <span class="says">says:</span>
                                                </div>
                                                <div class="comment-metadata">
                                                    <a routerLink="/blog-1"><span>April 24, 2019 at 10:59 am</span></a>
                                                </div>
                                            </footer>
                                            <div class="comment-content">
                                                <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                            </div>
                                            <div class="reply">
                                                <a  class="comment-reply-link">Reply</a>
                                            </div>
                                        </article>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        <div class="comment-respond">
                            <h3 class="comment-reply-title">Leave a Reply</h3>
                            <form class="comment-form">
                                <p class="comment-notes">
                                    <span id="email-notes">Your email address will not be published.</span>
                                    Required fields are marked
                                    <span class="required">*</span>
                                </p>
                                <p class="comment-form-author">
                                    <label>Name <span class="required">*</span></label>
                                    <input type="text" id="author" placeholder="Your Name*" name="author" required="required">
                                </p>
                                <p class="comment-form-email">
                                    <label>Email <span class="required">*</span></label>
                                    <input type="email" id="email" placeholder="Your Email*" name="email" required="required">
                                </p>
                                <p class="comment-form-url">
                                    <label>Website</label>
                                    <input type="url" id="url" placeholder="Website" name="url">
                                </p>
                                <p class="comment-form-comment">
                                    <label>Comment</label>
                                    <textarea name="comment" id="comment" cols="45" placeholder="Your Comment..." rows="5" maxlength="65525" required="required"></textarea>
                                </p>
                                <p class="comment-form-cookies-consent">
                                    <input type="checkbox" value="yes" name="wp-comment-cookies-consent" id="wp-comment-cookies-consent">
                                    <label for="wp-comment-cookies-consent">Save my name, email, and website in this browser for the next time I comment.</label>
                                </p>
                                <p class="form-submit">
                                    <input type="submit" name="submit" id="submit" class="submit" value="Post A Comment">
                                </p>
                            </form>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <div class="widget widget_search">
                        <h3 class="widget-title">Search</h3>
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field"   (keydown)="searchNow($event)" id="searchInput" placeholder="Search..." [(ngModel)]="searchText" [ngModelOptions]="{standalone:true}">
                            </label>
                            <button (click)="searchBlogPosts()" class="disabled" type="button"><i class="bx bx-search-alt"></i></button>
                        </form>
                    </div>
                    <div class="widget widget_ednuv_posts_thumb" *ngIf="BlogPostData?.similarArticles?.length" >
                        <h3 class="widget-title">Similar Posts</h3>
                        <article class="item"  *ngFor="let post of BlogPostData.similarArticles">
                            <a (click)="openPostDetails(post?.url)" class="thumb" style="width: 126px;">
                                <img  [src]="post?.imageUrl" class="fullimage cover w-100" [alt]="post?.imageAlt">
                                <!-- <span class="fullimage cover bg1" role="img"></span> -->
                            </a>
                            <div class="info">
                                <time datetime="2024-06-30">{{post?.createdAt | date:'medium'}}</time>
                                <h4 class="title usmall"><a >{{post?.title}}</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </div>
                    <div class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>
                        <ul>
                            <li *ngFor="let category of BlogPostData?.subCategories">
                                <a role="button">{{category?.name?.charAt(0)?.toUpperCase() + category?.name?.slice(1)}}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Tags</h3>
                        <div class="tagcloud">

                                <a *ngFor="let tag of BlogPostData?.tags"  role="button">{{tag?.name?.charAt(0)?.toUpperCase() + tag?.name?.slice(1)}}</a>

                        </div>
                    </div>
                </aside>
            </div>
        </div>

        <div class="row" *ngIf="isLoading">
            <div class="col-lg-12 col-md-12 my-5" >
                <div class="text-center d-flex justify-content-center align-items-center flex-column gap-3" style="font-size: 20px;">
                    <span
                        class="spinner-border spinner-border-lg spinner-color"
                        role="status"
                        aria-hidden="true"
                    ></span>
                    Loading Blog Post...
                </div>
            </div>
        </div>

        <div class="row" *ngIf="responseError">
            <div class="col-lg-12 col-md-12 my-5" >
                <div class="text-center" style="font-size: 20px;">
                    <h3>{{responseError || "Error 404!"}}</h3>
                </div>
            </div>
        </div>
    </div>
</div>
