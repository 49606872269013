import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import {
    provideHttpClient,
    withInterceptorsFromDi
  } from '@angular/common/http';
import { routes } from './app-routing.module';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';

export const appConfig: ApplicationConfig = {
    providers: [provideRouter(routes), provideClientHydration(), provideAnimations(),provideHttpClient(withInterceptorsFromDi())]
};
