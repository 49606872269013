import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
const Base_URL = 'https://wsdes223w.rvtechbytes.com';
// const Base_URL = 'http://localhost:5050';
@Injectable({
  providedIn: 'root',
})
export class DataService {
  public UserDetails = new BehaviorSubject<any>(null);
  accessToken = '';

  constructor(private http: HttpClient) {

  }

  private getHeaders():HttpHeaders {
    return new HttpHeaders({"Authorization":"Bearer "+this.accessToken})
   }

   public  getPosts(page:number,c?:string,sc?:string,s?:string): Observable<any> {
    return this.http.get(`
    ${Base_URL}/posts/blog?page=${page}
    ${s ? `&s=${s}` : ''}
    ${c ? `&c=${c}` : ''}
    ${sc ? `&sc=${sc}` : ''}
    `)
  }

  public  getHeaderContent(): Observable<any> {
    return this.http.get(`${Base_URL}/pages/header`)
  }

  public async getPostById(id:any): Promise<any> {
    return await this.http.get(`${Base_URL}/post/blog/${id}`).toPromise()
  }

  public async getPageById(id:any): Promise<any> {
    return await this.http.get(`${Base_URL}/page/${id}`).toPromise()
  }
  public async subscribeToNewsletter(email:string): Promise<any> {
    return await this.http.post(`https://uq8cztxjs1.execute-api.eu-west-2.amazonaws.com/dev/Newsletter/Subscribe`,{email}).toPromise()
  }
}
