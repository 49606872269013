import { Injectable } from '@angular/core';

declare var AWS: any; // Declare AWS as any type
@Injectable({
  providedIn: 'root',
})

export class AwsPollyService {
  private polly = new AWS.Polly({
    region: 'eu-west-2', // specify your region
    accessKeyId: 'AKIAZQ3DTNWM32CNUJMV',
    secretAccessKey: '7gim7AYbrOR17HMkVrHGC6OrCM5ngzpOUrlii4Gc'
  });

  constructor() { }

  synthesizeSpeech(text: string): Promise<Blob> {
    const params = {
      Text: text,
      OutputFormat: 'mp3',
      VoiceId: 'Aditi'
    };

    return this.polly.synthesizeSpeech(params).promise().then(data => {
      return new Blob([data.AudioStream], { type: 'audio/mpeg' });
    });
  }
}
