<app-header-style-one></app-header-style-one>

<!-- <div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="/">Home</a></li>
                <li>about-me</li>
            </ul>
            <h2>Instructor Details</h2>
        </div>
    </div>
</div> -->

<div
    class="instructor-details-area pt-100 pb-70"
    [class.dark-instructor-details-area]="themeService.isDark()"
>
    <div class="container">
        <div class="instructor-details-desc">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="instructor-details-sidebar">
                        <img src="assets/img/my-picture.jpg" alt="image">
                        <!-- <div class="instructor-details-meta">
                            <ul>
                                <li>
                                    <i class='bx bxs-group'></i>
                                    <span>Follower</span>
                                    <a routerLink="/">102</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>Following</span>
                                    <a routerLink="/">81</a>
                                </li>
                            </ul>
                        </div> -->
                        <div class="mt-3">
                            <a href="https://www.radsuntech.com/" target="_blank" class="default-btn mb-3" style="cursor: pointer;"><i class='bx bx-chevron-right icon-arrow before'></i><span class="label">Get More Info</span><i class="bx bx-chevron-right icon-arrow after"></i></a>
                        </div>
                        <ul class="social-link mt-1">
                            <!-- <li><a href="#" class="d-block facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li> -->
                            <!-- <li><a href="#" class="d-block twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li> -->
                            <!-- <li><a href="#" class="d-block instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li> -->
                            <li><a href="https://www.linkedin.com/in/radhika-vemura-a051339/" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="mailto:contact@rvtechbytes.com" class="d-block" target="_blank"><i class='fa fa-envelope'></i></a></li>
                            <!-- <li><a href="#" class="d-block pinterest" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> -->
                        </ul>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8">
                    <div class="instructor-details">
                        <h3>Radhika Vemura</h3>
                        <span class="sub-title">Tech Founder | CEO {{"@ RAD SUN"}} | Research Driven CTO or CTO As a service | Advisor | Tech - SAAS & Ecommerce | Global Traveller | Speaker | AWS Certified</span>
                        <p>A global traveller who attended numerous technology conferences and met visionary technology leaders and business entrepreneurs.
                            Nearly 20 plus years experience in IT industry, Technology division.
                            As a tech founder, assist software companies in completing their digital transformation by offering CTO as a service. Also assisted clients or continue to assist a company in seeking investor approval.
                            Investors, whether venture capitalists, angel investors, or corporate investors, are constantly looking for firms with strong technological underpinnings and growth prospects, and the CTO / Tech Advisor plays a crucial role in securing investor deals.
                            Expert in leading global technology teams to bring awe-inspiring products and solutions to market, resulting in a more engaging experience for customers through the design and transformation of technology usage.</p>

                        <p>I enjoy teaching/ training/ learning/ speaker/ discussing/ researching/ blogging/ innovating business-enhancing technologies.</p>

                        <p>Actively working as  'CEO/CTO As a Service' and with previous work experiences as Architect/PM/Team Leader/Developer/Teacher' and in various domains such as Forex, Commodities, Alternative Investments, Collateral Management, private Equity, and related financial instruments.
                            Others include E-commerce, finance, insurance, and human resources.</p>

                            <div class="mt-5 text-center">
                                <a class="default-btn mb-3" href="https://www.radsuntech.com/" target="_blank" style="cursor: pointer;"><i class='bx bx-chevron-right icon-arrow before'></i><span class="label">Visit Us</span><i class="bx bx-chevron-right icon-arrow after"></i></a>
                            </div>
                        <!-- <div class="instructor-details-info">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <h3>My Education</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Harvard University</span>
                                            Bachelor in Mathematics
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>University of Toronto</span>
                                            Bachelor in English
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <h3>My Experience</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>Envato</span>
                                            2000-2010
                                        </li>
                                        <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>EnvyTheme</span>
                                            2010-2024
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="section-title text-start">
            <h2>Courses by Jonkin Jullinor</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/" class="d-block">Business</a>
                        </div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Steven Smith</span>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">Raque Professional IT Expert Certificate Course</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <div class="rating-total">
                                5.0 (1 rating)
                            </div>
                        </div>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 10 students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 6 lessons
                            </li>
                            <li class="courses-price">
                                Free
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/" class="d-block">Design</a>
                        </div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Sarah Taylor</span>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">Certified Graphic Design with Free Project Course</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star-half'></i>
                            </div>
                            <div class="rating-total">
                                4.5 (2 rating)
                            </div>
                        </div>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 15 students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 10 lessons
                            </li>
                            <li class="courses-price">
                                $250
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses3.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/" class="d-block">Development</a>
                        </div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user3.jpg" class="rounded-circle mr-2" alt="image">
                            <span>James Anderson</span>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">Photography Crash Course for Photographer</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bx-star'></i>
                            </div>
                            <div class="rating-total">
                                4.0 (1 rating)
                            </div>
                        </div>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 5 students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 5 lessons
                            </li>
                            <li class="courses-price">
                                $150
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>
