import { Component, OnInit, HostListener } from '@angular/core';
import { ThemeCustomizerService } from '../theme-customizer/theme-customizer.service';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { DataService } from 'src/app/core/services/data.service';

@Component({
    selector: 'app-header-style-one',
    standalone: true,
    imports: [RouterLinkActive, NgClass, RouterLink, NgFor, NgIf],
    templateUrl: './header-style-one.component.html',
    styleUrls: ['./header-style-one.component.scss']
})
export class HeaderStyleOneComponent implements OnInit {
    page : string;
    isSticky: boolean = false;
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    isToggled = false;
    pages : any[] = [];
    constructor(
        public themeService: ThemeCustomizerService,
        private data:DataService,
        private router:Router,
        private activeRoute:ActivatedRoute
    ) {
        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
        activeRoute.params.subscribe(params=>{
            this.page = null;
            if(params["page"]){
                this.page = params["page"]
            }

        })
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    ngOnInit(): void {
        this.getHeaderContent();
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    classApplied2 = false;
    toggleClass2() {
        this.classApplied2 = !this.classApplied2;
    }

    classApplied3 = false;
    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }

    getHeaderContent(){
        this.data.getHeaderContent().subscribe((res:any)=>{
            this.pages = res;

        },err=>{

        })
    }

    filterPosts(page:any){
        this.router.navigate([`/view/${page}`])
      }

      pagesLengthMoreThenFour(){
        return this?.pages?.length > 4;
      }

      getFirstFourPages(){
        if(this.pagesLengthMoreThenFour()){
          return this.pages.slice(0,4)
        }
        return this.pages;
      }

      getLastPages(){
        if(this.pagesLengthMoreThenFour()){
          return this.pages.slice(4)
        }
        return [];
      }

      isCurrentPage(page:any){
        const active = this.page === page;
        return active;
      }

}
