import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { DataService } from 'src/app/core/services/data.service';

@Component({
    selector: 'app-footer',
    standalone: true,
    imports: [NgxScrollTopModule, RouterLink, FormsModule,ReactiveFormsModule, NgIf],
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    subscribeForm: FormGroup = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email])
    });
    isSubscribed: boolean = false;
    submitted = false;
    get f() { return this.subscribeForm.controls; }
    constructor(
        private data:DataService
    ) { }


    ngOnInit(): void {
        const isSubscribed = localStorage.getItem('isSubscribed');
        if (isSubscribed) {
            this.isSubscribed = true;
        }
    }


    onSubmit() {
       this.submitted = true;
       if (this.subscribeForm.valid) {
           this.isSubscribed = true;
           this.subscribeToNewsletter();
       }
   }

    subscribeToNewsletter(){
       this.data.subscribeToNewsletter(this.subscribeForm.value.email).then(res=>{
           localStorage.setItem('isSubscribed', 'true');
       }).catch(err=>{
           console.error(err);
       })
    }



}
