import { Component, OnInit } from "@angular/core";
import { ThemeCustomizerService } from "../../common/theme-customizer/theme-customizer.service";
import { HeaderStyleTwoComponent } from "../../common/header-style-two/header-style-two.component";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { CommonModule } from "@angular/common";
import { HeaderStyleOneComponent } from "../../common/header-style-one/header-style-one.component";
import { DataService } from "src/app/core/services/data.service";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { AwsPollyService } from "src/app/core/services/polly.service";

@Component({
  selector: "app-blog-details-page",
  standalone: true,
  imports: [HeaderStyleOneComponent, RouterLink, CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: "./blog-details-page.component.html",
  styleUrls: ["./blog-details-page.component.scss"]
})
export class BlogDetailsPageComponent implements OnInit {
  isToggled = false;
  BlogPostData: any = {};
  postId: any;
  isLoading = true;
  responseError = '';
  searchText = '';
  generatedAudio = '';
  isLoadingAudio = false;
  subscribeForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
});
isSubscribed: boolean = false;
submitted = false;
get f() { return this.subscribeForm.controls; }
  constructor(public themeService: ThemeCustomizerService,
    private router:Router, private data:DataService,
    private activatedRoute: ActivatedRoute,
    private polly:AwsPollyService
    ) {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
    activatedRoute.params.subscribe(res=>{
        this.postId = res.post;
        if(this.postId){
            this.getBlogDetails(this.postId);
        }
    })
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  ngOnInit(): void {
    const isSubscribed = localStorage.getItem('isSubscribed');
    if (isSubscribed) {
        this.isSubscribed = true;
    }
  }


onSubmit() {
     ;
    this.submitted = true;
    if (this.subscribeForm.valid) {
        this.isSubscribed = true;
        this.subscribeToNewsletter();
    }
}

 subscribeToNewsletter(){
    this.data.subscribeToNewsletter(this.subscribeForm.value.email).then(res=>{
        console.log(res);
        localStorage.setItem('isSubscribed', 'true');
    }).catch(err=>{
        console.error(err);
    })
 }


  async  getBlogDetails(id:any){
    this.isLoading = true;
    this.responseError = '';
    await this.data.getPostById(id).then((res:any)=>{
        const BlogDetailsDiv = document.getElementById('blogDescriptionDiv');
        this.BlogPostData = res;
        BlogDetailsDiv.innerHTML = this.BlogPostData?.description;
        const similarArticles = res?.similarArticles;
        if(!res?.nextPost){
            this.BlogPostData.nextPost = similarArticles?.length ?  similarArticles[0] : null;
        }
        if(!res?.prevPost){
            this.BlogPostData.prevPost = similarArticles?.length ?  similarArticles[similarArticles?.length-1] : null;
        }
        ;
    }).catch(err=>{
        ;
        this.responseError = err?.error?.error;
    }).finally(()=>{
        this.isLoading = false;
    })
  }

  openPostDetails(postUrl:any){
    if(postUrl == this.postId)return;
    this.BlogPostData = {};
    this.router.navigate([`/single-blog/${postUrl}`])
  }

  searchBlogPosts(){
    this.router.navigate(['/'],{queryParams:{searchText:this.searchText}})
  }

  getPostShareUrl(){
    return encodeURIComponent(window.location.href);
  }

  getpostContent(){
   return document.getElementById('blogDescriptionDiv')?.innerText;
  }

  searchNow($event:any){
    if($event.key == 'Enter'){
        this.searchBlogPosts()
    }
  }

  generateAudio() {
    const div = document.getElementById('blogDescriptionDiv');
    const text = div.innerText?.replace(/(\r\n|\n|\r)/gm, " ")?.slice(0, 3000);
     ;
    this.isLoadingAudio = true;
    this.polly.synthesizeSpeech(text).then(audioBlob => {
      const audioUrl = URL.createObjectURL(audioBlob)
      this.generatedAudio = audioUrl;
    }).catch(err => {
       ;
    }).finally(() => {
        this.isLoadingAudio = false;
    })
  }
}
