import { Component, OnInit } from "@angular/core";
import { ThemeCustomizerService } from "../../common/theme-customizer/theme-customizer.service";
import { HeaderStyleOneComponent } from "../../common/header-style-one/header-style-one.component";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { NgxPaginationModule } from "ngx-pagination";
import { CommonModule, PlatformLocation } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { AppModule } from "src/app/app.module";
import { SearchFilterPipe } from "src/app/search-filter.pipe";
import { DataService } from "src/app/core/services/data.service";

@Component({
  selector: "app-blog-full-width-page",
  standalone: true,
  imports: [
    HeaderStyleOneComponent,
    RouterLink,
    NgxPaginationModule,
    CommonModule,
    FormsModule,
    AppModule,
  ],
  templateUrl: "./view-blog-page.component.html",
  styleUrls: ["./view-blog-page.component.scss"],
  providers: [SearchFilterPipe]
})
export class ViewBlogPageComponent implements OnInit {
isToggled = false;
q: any;
searchText: string;
pageData : any = {};
activePageId :string = '';
  isLoading = false;
  isError = false;
  constructor(
    public themeService: ThemeCustomizerService,
    private pLocation: PlatformLocation,
    private data:DataService,
    private router:Router,
    private activeRoute:ActivatedRoute
  ) {
    this.themeService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
    activeRoute.params.subscribe(params=>{
        this.activePageId = null;
        if(params?.page){
            this.activePageId = params?.page
        }
        this.getPageById()
    })
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  ngOnInit(): void {

  }

  getPageById(){
    this.isLoading = true;
    this.data.getPageById(this.activePageId).then((res:any)=>{
        this.pageData = res;

        this.isLoading = false;
        }).catch(err=>{

        this.isLoading = false;
        this.isError = true;
        })
  }

  openPostDetails(postUrl:any){
    this.router.navigate([`/single-blog/${postUrl}`])
  }

  searchNow($event:any){

  }
}
