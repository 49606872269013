<app-header-style-one></app-header-style-one>

<!-- <div class="page-title-area page-title-style-three item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Blog</li>
            </ul>
            <h2>Home</h2>
        </div>
    </div>
</div> -->

<div class="blog-area ptb-100" [class.dark-blog-area]="themeService.isDark()">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3">
            </div>
            <div class="col-lg-6 mb-3">
                <div class="pagine">
                    <div class="search-overlay-form">
                        <form>
                            <input type="text"
                            class="input-search position-relative"
                             placeholder="Search here..."
                             [(ngModel)]="searchText"
                            (keydown)="searchNow($event)"
                            [ngModelOptions]="{standalone: true}"
                            id="searchInput"
                            >

                            <a href="javascript:void(0);" *ngIf="searchText" (click)="resetSearch()" class="btn-clear" >
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"/></svg>
                            </a>

                            <button type="submit"
                            [disabled]="isLoading"
                            (click)="searchBlogPosts()">
                            <i class='bx bx-search-alt'></i>
                        </button>

                        </form>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">

            </div>
        </div>
        <div class="row">
            <div [style.display]="isLoading? 'none' : ''" class="col-xxl-3 col-lg-4 col-md-6"
                *ngFor="let post of blogPosts" (click)="openPostDetails(post?.url)">
                <div class="single-blog-post mb-30">
                    <div class="post-image">
                        <a

                        class="d-block">
                            <img  [src]="post.imageUrl" [alt]="post.imageAlt"  alt="image">
                        </a>
                        <div class="tag">
                            <a >{{ post?.category?.name }}</a>
                        </div>
                    </div>
                    <div class="post-content">
                        <ul class="post-meta">
                            <li class="post-author">
                                <img [src]="post?.publishedBy?.image" class="d-inline-block rounded-circle mr-2" alt="image">
                                <a  class="d-inline-block">Continue Reading</a>
                            </li>
                            <li><a>&nbsp;&nbsp;-&nbsp;{{post?.createdAt | date : "mediumDate"}}</a></li>
                        </ul>
                        <h3 style="font-weight: 400 !important;"><a role="button" class="d-inline-block">{{post?.title.slice(0, 50) + "..."}}</a></h3>
                        <!-- <a role="button" class="read-more-btn">Read More <i
                                class='bx bx-right-arrow-alt'></i></a> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 my-5" *ngIf="isLoading">
                <div
                  class="text-center d-flex justify-content-center align-items-center flex-column gap-3"
                  style="font-size: 20px"
                >
                  <span
                    class="spinner-border spinner-border-lg spinner-color"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading Blog Posts...
                </div>
              </div>
              <div
                class="col-lg-12 col-md-12 my-5"
                *ngIf="blogPosts.length == 0 && !isLoading"
              >
                <div class="text-center">
                  <h3>No Blog Posts Found</h3>
                </div>
              </div>
              <div
                class="col-lg-12 col-md-12"
                [style.display]="blogPosts.length == 0 || isLoading ? 'none' : ''"
              >
                <div class="pagination-area text-center">
                  <a
                    class="prev page-numbers"
                    [ngClass]="{
                      disabled: pagination.hasPrevPage != true
                    }"
                    (click)="previousPage()"
                    ><i class="bx bx-chevrons-left"></i
                  ></a>
                  <a
                    class="page-numbers"
                    *ngFor="let page of getPagesArray()"
                    [ngClass]="{
                      current: page == currentPage,
                      disabled: page == currentPage
                    }"
                    (click)="moveToPage(page)"
                    >{{ page }}</a
                  >
                  <a
                    class="next page-numbers"
                    [ngClass]="{
                      disabled: pagination.hasNextPage != true
                    }"
                    (click)="nextPage()"
                    ><i class="bx bx-chevrons-right"></i
                  ></a>
                </div>
              </div>
        </div>
    </div>
</div>
